import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from "../../assets/images/run-payroll/delete-filled.svg";

const styles = {
    error: {
        "& div input": {
            borderColor: "red"
        }
    }
};

const EmployeesPayAdjustment = ({
                                    is_overtime_enabled,
                                    add_stop_bonus,
                                    add_package_bonus,
                                    add_per_package,
                                    add_per_stop,
                                    register,
                                    touchedFields,
                                    errors,
                                    adding_daily_pay,
                                    setValue,
                                    stop_bonuses,
                                    package_bonuses,
                                    deleted_stop_bonuses,
                                    deleted_package_bonuses,
                                    is_work_area_enabled,
                                    isActive,
                                    weekly_rate_w2,
                                    customErrors,
                                    setCustomErrors
                                }) => {
    const [stopBonusesArray, setStopBonusesArray] = useState(
        stop_bonuses?.length > 0 ? stop_bonuses : [{ id: 0, per_item: "stop" }]
    );
    const [packageBonusesArray, setPackageBonusesArray] = useState(
        package_bonuses?.length > 0 ? package_bonuses : [{ id: 0, per_item: "package" }]
    );

    const handleDailyRateChange = () => {
        if (!isActive || !weekly_rate_w2) {
            return;
        }

        setValue("subtracting_daily_pay", adding_daily_pay === 1 ? 1 : 0, {
            shouldValidate: true,
            shouldDirty: true
        });

        setValue("adding_daily_pay", adding_daily_pay === 1 ? 0 : 1, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const handleWorkAreaChange = () => {
        if (!isActive) {
            return;
        }

        setValue("is_work_area_enabled", is_work_area_enabled === 1 ? 0 : 1, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const handleIsOvertimeChange = () => {
        if (!isActive) {
            return;
        }

        setValue("is_overtime_enabled", is_overtime_enabled === 0 ? 1 : 0, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const handlePerStopChange = () => {
        if (!isActive) {
            return;
        }

        setValue("add_per_stop", add_per_stop === 0 ? 1 : 0, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const handlePerPackageChange = () => {
        if (!isActive) {
            return;
        }

        setValue("add_per_package", add_per_package === 0 ? 1 : 0, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const handlePackageBonusChange = () => {
        if (!isActive) {
            return;
        }

        setPackageBonusesArray(
            package_bonuses?.length > 1 ? package_bonuses : [{ id: 0, per_item: "package" }]
        );
        setValue("package_bonuses", package_bonuses, {
            shouldValidate: true,
            shouldDirty: true
        });
        setValue("add_package_bonus", add_package_bonus === 0 ? 1 : 0, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const handleStopBonusChange = () => {
        if (!isActive) {
            return;
        }

        setStopBonusesArray(stop_bonuses?.length > 1 ? stop_bonuses : [{ id: 0, per_item: "stop" }]);
        setValue("stop_bonuses", stop_bonuses, {
            shouldValidate: true,
            shouldDirty: true
        });
        setValue("add_stop_bonus", add_stop_bonus === 0 ? 1 : 0, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const handlePackageAddMore = () => {
        if (!isActive) {
            return;
        }

        setPackageBonusesArray([
            ...packageBonusesArray,
            {
                price: null,
                threshold: null,
                id: packageBonusesArray.length + 1,
                per_item: "package"
            }
        ]);
    };

    const handleStopAddMore = () => {
        if (!isActive) {
            return;
        }

        setStopBonusesArray([
            ...stopBonusesArray,
            {
                price: null,
                threshold: null,
                id: stopBonusesArray.length + 1,
                per_item: "stop"
            }
        ]);
    };

    const handleNumberChange = (inputName, e) => {
        const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
            return "." + m1.replace(/\./g, "");
        });
        setValue(inputName, value, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const handleStopDeleteBonus = (index, e) => {
        e.preventDefault();
        const item = stop_bonuses.find((el, indexN) => indexN === index);
        const newBonusesArr = [...stopBonusesArray];
        newBonusesArr.splice(index, 1);
        setStopBonusesArray(newBonusesArr);
        setValue(
            "stop_bonuses",
            stop_bonuses.filter((el, indexN) => indexN !== index),
            {
                shouldValidate: true,
                shouldDirty: true
            }
        );

        if (item.id > 0) {
            setValue("deleted_stop_bonuses", [...deleted_stop_bonuses, item.id]);
        }
    };

    const handlePackageDeleteBonus = (index, e) => {
        e.preventDefault();
        const item = package_bonuses.find((el, indexN) => indexN === index);
        const newBonusesArr = [...packageBonusesArray];
        newBonusesArr.splice(index, 1);
        setPackageBonusesArray(newBonusesArr);
        setValue(
            "package_bonuses",
            package_bonuses.filter((el, indexN) => indexN !== index),
            {
                shouldValidate: true,
                shouldDirty: true
            }
        );

        if (item.id > 0) {
            setValue("deleted_package_bonuses", [...deleted_package_bonuses, item.id]);
        }
    };

    const handlePackageThresholdChange = (index, e) => {
        const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
            return "." + m1.replace(/\./g, "");
        });

        if (package_bonuses?.length > 1) {
            const arrayOfMatches = package_bonuses
                .map((el) => {
                    if (
                        el.threshold &&
                        +el.threshold > 0 &&
                        +e.target?.value > 0 &&
                        +el.threshold === +e.target?.value
                    ) {
                        return true;
                    }
                    return false;
                })
                .filter((el) => el === true);

            if (arrayOfMatches.length > 0) {
                const errorsData = {
                    ...errors,
                    [`package_bonuses_${index}_threshold`]: "Daily threshold must be unique"
                };
                setCustomErrors(errorsData);
            } else {
                const newErrors = { ...errors };
                delete newErrors[`package_bonuses_${index}_threshold`];
                setCustomErrors(newErrors);
            }
        }

        setValue(`package_bonuses[${index}][threshold]`, value, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    const handleStopThresholdChange = (index, e) => {
        const value = e.target?.value.replace(/[^\d.]/g, "").replace(/\.([.\d]+)$/, function (m, m1) {
            return "." + m1.replace(/\./g, "");
        });

        if (stop_bonuses?.length > 1) {
            const arrayOfMatches = stop_bonuses
                .map((el) => {
                    if (
                        el.threshold &&
                        +el.threshold > 0 &&
                        +e.target?.value > 0 &&
                        +el.threshold === +e.target?.value
                    ) {
                        return true;
                    }
                    return false;
                })
                .filter((el) => el === true);

            if (arrayOfMatches.length > 0) {
                const errorsData = {
                    ...errors,
                    [`stop_bonuses_${index}_threshold`]: "Daily threshold must be unique"
                };
                setCustomErrors(errorsData);
            } else {
                const newErrors = { ...errors };
                delete newErrors[`stop_bonuses_${index}_threshold`];
                setCustomErrors(newErrors);
            }
        }

        setValue(`stop_bonuses[${index}][threshold]`, value, {
            shouldValidate: true,
            shouldDirty: true
        });
    };

    useEffect(() => {
        if (!weekly_rate_w2 && !adding_daily_pay) {
            setValue("subtracting_daily_pay", 0, {
                shouldValidate: true,
                shouldDirty: true
            });

            setValue("adding_daily_pay", 1, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    }, [weekly_rate_w2]);

    return (
        <div className="popup-dis-sec">
            <div className="popup-content-sec weekly-salary">
                <div className="form-item full-width weekly-rate-w-two">
                    <label>Weekly Rate</label>
                    <div className="input-box">
                        <span>$</span>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder=" "
                            name="weekly_rate_w2"
                            disabled={!isActive}
                            {...register("weekly_rate_w2")}
                            onChange={handleNumberChange.bind(null, "weekly_rate_w2")}
                            error={touchedFields.weekly_rate_w2 && !!errors.weekly_rate_w2?.message}
                            helperText={errors.weekly_rate_w2?.message}
                        />
                    </div>
                </div>
                <div className="add-bonus-sec weekly-salary">
                    <div
                        className={`option-toggle add-bonus-toggle ${!weekly_rate_w2 ? "disabled" : ""}`}
                        onClick={handleDailyRateChange}>
                        <span className={`toggle-btn ${adding_daily_pay === 0 ? "active" : ""}`}></span>
                        <span>Weekly Rate Cap</span>
                    </div>
                </div>
            </div>

            <div className="form-items-container">
                <div className="form-item">
                    <label>Paid Time Off (PTO)</label>
                    <div className="input-box">
                        <span>$</span>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder=" "
                            name="paid_time_off"
                            disabled={!isActive}
                            {...register("paid_time_off")}
                            onChange={handleNumberChange.bind(null, "paid_time_off")}
                            error={touchedFields.paid_time_off && !!errors.paid_time_off?.message}
                            helperText={errors.paid_time_off?.message}
                        />
                    </div>
                </div>
                <div className="form-item pr2">
                    <label>Training Rate</label>
                    <div className="input-box">
                        <span>$</span>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder=" "
                            name="training_rate"
                            disabled={!isActive}
                            {...register("training_rate")}
                            onChange={handleNumberChange.bind(null, "training_rate")}
                            error={touchedFields.training_rate && !!errors.training_rate?.message}
                            helperText={errors.training_rate?.message}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <label>6th Day Bonus</label>
                    <div className="input-box">
                        <span>$</span>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder=" "
                            name="six_day_bonus"
                            disabled={!isActive}
                            {...register("six_day_bonus")}
                            onChange={handleNumberChange.bind(null, "six_day_bonus")}
                            error={touchedFields.six_day_bonus && !!errors.six_day_bonus?.message}
                            helperText={errors.six_day_bonus?.message}
                        />
                    </div>
                </div>
            </div>

            <div className="add-bonus-sec">
                <div className="option-toggle add-bonus-toggle" onClick={handleIsOvertimeChange}>
                    <span className={`toggle-btn ${is_overtime_enabled ? "active" : ""}`}></span>
                    <span>Add Overtime</span>
                </div>
            </div>

            <h5 style={{ margin: "12px 0 24px" }}>Weekly Service Worksheet</h5>

            <div className="add-bonus-sec weekly-salary" style={{ marginBottom: "32px" }}>
                <div className={`option-toggle add-bonus-toggle`} onClick={handleWorkAreaChange}>
                    <span className={`toggle-btn ${is_work_area_enabled === 1 ? "active" : ""}`}></span>
                    <span>Work Area Rate</span>
                </div>
            </div>

            {is_work_area_enabled === 0 && (
                <>
                    <h5 style={{ margin: "0 0 24px" }}>Chance Statement</h5>

                    <div className="form-items-container" style={{ marginBottom: "13px" }}>
                        <div className="form-item">
                            <label>Daily Rate</label>
                            <div className="input-box">
                                <span>$</span>
                                <TextField
                                    fullWidth
                                    type="text"
                                    placeholder=" "
                                    name="daily_pay"
                                    disabled={!isActive}
                                    {...register("daily_pay")}
                                    onChange={handleNumberChange.bind(null, "daily_pay")}
                                    error={touchedFields.daily_pay && !!errors.daily_pay?.message}
                                    helperText={errors.daily_pay?.message}
                                />
                            </div>
                        </div>
                        <div className="form-item">
                            <label>Hourly Rate</label>
                            <div className="input-box">
                                <span>$</span>
                                <TextField
                                    fullWidth
                                    type="text"
                                    placeholder=" "
                                    name="hourly_rate"
                                    disabled={!isActive}
                                    {...register("hourly_rate")}
                                    onChange={handleNumberChange.bind(null, "hourly_rate")}
                                    error={touchedFields.hourly_rate && !!errors.hourly_rate?.message}
                                    helperText={errors.hourly_rate?.message}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="add-bonus-sec">
                        <div className="option-toggle add-bonus-toggle" onClick={handlePerStopChange}>
                            <span className={`toggle-btn ${add_per_stop ? "active" : ""}`}></span>
                            <span>Add Per Stop </span>
                        </div>

                        {Boolean(add_per_stop) && (
                            <div className="add-bonus-field">
                                <div className="three-column">
                                    <div className="form-item">
                                        <label>Pickup</label>
                                        <div className="input-box">
                                            <span>$</span>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder="0.00"
                                                name="stop_pickup_rate"
                                                disabled={!isActive}
                                                {...register("stop_pickup_rate")}
                                                onChange={handleNumberChange.bind(null, "stop_pickup_rate")}
                                                error={touchedFields.stop_pickup_rate && !!errors.stop_pickup_rate?.message}
                                                helperText={errors.stop_pickup_rate?.message}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-item">
                                        <label>Delivery</label>
                                        <div className="input-box">
                                            <span>$</span>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder="0.00"
                                                name="stop_delivery_rate"
                                                disabled={!isActive}
                                                {...register("stop_delivery_rate")}
                                                onChange={handleNumberChange.bind(null, "stop_delivery_rate")}
                                                error={
                                                    touchedFields.stop_delivery_rate && !!errors.stop_delivery_rate?.message
                                                }
                                                helperText={errors.stop_delivery_rate?.message}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-item">
                                        <label>E-commerce</label>
                                        <div className="input-box">
                                            <span>$</span>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder="0.00"
                                                name="stop_ecommerce_rate"
                                                disabled={!isActive}
                                                {...register("stop_ecommerce_rate")}
                                                onChange={handleNumberChange.bind(null, "stop_ecommerce_rate")}
                                                error={
                                                    touchedFields.stop_ecommerce_rate && !!errors.stop_ecommerce_rate?.message
                                                }
                                                helperText={errors.stop_ecommerce_rate?.message}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="add-bonus-sec">
                        <div className="option-toggle add-bonus-toggle" onClick={handlePerPackageChange}>
                            <span className={`toggle-btn ${add_per_package ? "active" : ""}`}></span>
                            <span>Add Per Package </span>
                        </div>

                        {Boolean(add_per_package) && (
                            <div className="add-bonus-field">
                                <div className="three-column">
                                    <div className="form-item">
                                        <label>Pickup</label>
                                        <div className="input-box">
                                            <span>$</span>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder="0.00"
                                                name="package_pickup_rate"
                                                disabled={!isActive}
                                                {...register("package_pickup_rate")}
                                                onChange={handleNumberChange.bind(null, "package_pickup_rate")}
                                                error={
                                                    touchedFields.package_pickup_rate && !!errors.package_pickup_rate?.message
                                                }
                                                helperText={errors.package_pickup_rate?.message}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-item">
                                        <label>Delivery</label>
                                        <div className="input-box">
                                            <span>$</span>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder="0.00"
                                                name="package_delivery_rate"
                                                disabled={!isActive}
                                                {...register("package_delivery_rate")}
                                                onChange={handleNumberChange.bind(null, "package_delivery_rate")}
                                                error={
                                                    touchedFields.package_delivery_rate &&
                                                    !!errors.package_delivery_rate?.message
                                                }
                                                helperText={errors.package_delivery_rate?.message}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-item">
                                        <label>E-commerce</label>
                                        <div className="input-box">
                                            <span>$</span>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder="0.00"
                                                name="package_ecommerce_rate"
                                                disabled={!isActive}
                                                {...register("package_ecommerce_rate")}
                                                onChange={handleNumberChange.bind(null, "package_ecommerce_rate")}
                                                error={
                                                    touchedFields.package_ecommerce_rate &&
                                                    !!errors.package_ecommerce_rate?.message
                                                }
                                                helperText={errors.package_ecommerce_rate?.message}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="add-bonus-sec">
                        <div className="option-toggle add-bonus-toggle" onClick={handleStopBonusChange}>
                            <span className={`toggle-btn ${add_stop_bonus ? "active" : ""}`}></span>
                            <span>Add Stop Threshold</span>
                        </div>

                        {Boolean(add_stop_bonus) && (
                            <div className="add-bonus-field bonus">
                                {stopBonusesArray.map((el, index) => (
                                    <div className="form-item-container" key={index}>
                                        <div className="form-item">
                                            <label
                                                className={!!customErrors[`stop_bonuses_${index}_threshold`] && "error"}>
                                                Stop Threshold
                                            </label>
                                            <div className="input-box">
                                                <TextField
                                                    sx={customErrors[`stop_bonuses_${index}_threshold`] ? styles.error : {}}
                                                    fullWidth
                                                    type="text"
                                                    placeholder="100"
                                                    name={`stop_bonuses[${index}][threshold]`}
                                                    disabled={!isActive}
                                                    {...register(`stop_bonuses[${index}][threshold]`)}
                                                    onChange={handleStopThresholdChange.bind(null, index)}
                                                    error={
                                                        touchedFields.stop_bonuses?.[index]?.threshold &&
                                                        !!customErrors[`stop_bonuses_${index}_threshold`]
                                                    }
                                                    helperText={customErrors[`stop_bonuses_${index}_threshold`]}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <label>Per Stop</label>
                                            <div className="input-box">
                                                <span>$</span>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    placeholder="1.00"
                                                    name={`stop_bonuses[${index}][price]`}
                                                    disabled={!isActive}
                                                    {...register(`stop_bonuses[${index}][price]`)}
                                                    onChange={handleNumberChange.bind(null, `stop_bonuses[${index}][price]`)}
                                                    error={touchedFields.price && !!errors.price?.message}
                                                    helperText={errors.price?.message}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            disabled={!isActive}
                                            className="document-more-btn menu-item"
                                            onClick={handleStopDeleteBonus.bind(null, index)}>
                                            <img src={DeleteIcon} alt="" />
                                        </button>
                                    </div>
                                ))}

                                {stopBonusesArray.length < 3 && (
                                    <div className="bonus-add-more" onClick={handleStopAddMore}>
                                        <span>+</span> Add more
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="add-bonus-sec">
                        <div className="option-toggle add-bonus-toggle" onClick={handlePackageBonusChange}>
                            <span className={`toggle-btn ${add_package_bonus ? "active" : ""}`}></span>
                            <span>Add Package Threshold</span>
                        </div>

                        {Boolean(add_package_bonus) && (
                            <div className="add-bonus-field bonus">
                                {packageBonusesArray.map((el, index) => (
                                    <div className="form-item-container" key={index}>
                                        <div className="form-item">
                                            <label
                                                className={!!customErrors[`package_bonuses_${index}_threshold`] && "error"}>
                                                Package Threshold
                                            </label>
                                            <div className="input-box">
                                                <TextField
                                                    sx={
                                                        customErrors[`package_bonuses_${index}_threshold`] ? styles.error : {}
                                                    }
                                                    fullWidth
                                                    type="text"
                                                    placeholder="100"
                                                    name={`package_bonuses[${index}][threshold]`}
                                                    disabled={!isActive}
                                                    {...register(`package_bonuses[${index}][threshold]`)}
                                                    onChange={handlePackageThresholdChange.bind(null, index)}
                                                    error={
                                                        touchedFields.package_bonuses?.[index]?.threshold &&
                                                        !!customErrors[`package_bonuses_${index}_threshold`]
                                                    }
                                                    helperText={customErrors[`package_bonuses_${index}_threshold`]}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-item">
                                            <label>Per Package</label>
                                            <div className="input-box">
                                                <span>$</span>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    placeholder="1.00"
                                                    name={`package_bonuses[${index}][price]`}
                                                    disabled={!isActive}
                                                    {...register(`package_bonuses[${index}][price]`)}
                                                    onChange={handleNumberChange.bind(
                                                        null,
                                                        `package_bonuses[${index}][price]`
                                                    )}
                                                    error={touchedFields.price && !!errors.price?.message}
                                                    helperText={errors.price?.message}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            disabled={!isActive}
                                            className="document-more-btn menu-item"
                                            onClick={handlePackageDeleteBonus.bind(null, index)}>
                                            <img src={DeleteIcon} alt="" />
                                        </button>
                                    </div>
                                ))}

                                {packageBonusesArray.length < 3 && (
                                    <div className="bonus-add-more" onClick={handlePackageAddMore}>
                                        <span>+</span> Add more
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default EmployeesPayAdjustment;
